import { useRouteMatch } from 'react-router-dom';
import { APP_ROLES } from '../constants';

export default function useMenu() {
	const appRoutes = [
		{
			id: 'client-orgs-nav',
			title: 'Client orgs',
			icon: 'home',
			pathname: '/client-orgs',
			active: useRouteMatch('/client-orgs'),
			roles: [APP_ROLES.CLIENT_ADMIN, APP_ROLES.ESI_CLIENT_ADMIN]
		},
		{
			id: 'client-projects-nav',
			title: 'Client projects',
			icon: 'work',
			pathname: '/client-projects',
			active: useRouteMatch({ path: ['/client-projects', '/si-partner-projects'] }),
			roles: [APP_ROLES.CLIENT_ADMIN, APP_ROLES.ESI_CLIENT_ADMIN]
		},
		{
			id: 'template-manager-nav',
			title: 'Template manager',
			icon: 'media-file',
			pathname: '/template-manager',
			active: useRouteMatch('/template-manager'),
			roles: [APP_ROLES.TEMPLATE_ADMIN, APP_ROLES.ESI_TEMPLATE_ADMIN]
		},
		{
			id: 'si-partners-nav',
			title: 'SI Partners',
			icon: 'user-group',
			pathname: '/si-partners',
			active: useRouteMatch('/si-partners'),
			roles: [APP_ROLES.CLIENT_ADMIN]
		},
		{
			id: 'roles-nav',
			title: 'Roles',
			icon: 'user-avatar',
			pathname: '/roles',
			active: useRouteMatch('/roles'),
			roles: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ESI_SUPER_ADMIN]
		},
		{
			id: 'projects-nav',
			title: 'Projects',
			icon: 'home',
			pathname: '/projects',
			active: useRouteMatch({ path: ['/projects', '/si-projects'] }),
			roles: [APP_ROLES.PROJECT_USER, APP_ROLES.ESI_PROJECT_USER]
		},
		{
			id: 'certificate',
			title: 'Certificate',
			icon: 'action-view-bookmarks',
			pathname: '/certificate',
			active: useRouteMatch('/certificate'),
			roles: [APP_ROLES.CERTIFICATE_USER, APP_ROLES.ESI_CERTIFICATE_USER]
		},
		{
			id: 'members-nav',
			title: 'Members',
			icon: 'user-avatar',
			pathname: '/members',
			active: useRouteMatch('/members'),
			roles: [APP_ROLES.MEMBER_USER, APP_ROLES.ESI_MEMBER_USER]
		},
		{
			id: 'partners-nav',
			title: 'Integration Services',
			icon: 'toolbox',
			pathname: '/partners',
			active: useRouteMatch('/partners'),
			roles: [APP_ROLES.PROJECT_USER]
		}
	];

	return [{ appRoutes }];
}
