import React from 'react';
import { SdfButton, SdfFocusPane, SdfLink } from '@synerg/react-components';
import { ESI_HELP_LINK } from '../constants';


export default function HelpModalESI({ isOpenEsiHelpModal, setIsOpenEsiHelpModal }) {

    const closeModal = () => {
        setIsOpenEsiHelpModal(false);
    };

    return (
        <>
            <SdfFocusPane
                className="text-default"
                paneType="floating"
                visible={isOpenEsiHelpModal}
                closeable={true}
                heading={'Need help?'}
                dismissOnClickOutside={true}
                hideDismissButton={true}
                hideAcceptButton={true}
                onSdfDismiss={() => {
                    closeModal();
                }}
                spacing="loose"
                size="sm"
            >
                <div className="text-body-02">
                    <div>
                        ADP eService is a web portal to provide you as an ADP client access to service information.
                    </div>

                    <div className='mt-4' >
                        The portal can be used to submit new service requests and check the status of service requests already in progress.
                    </div>

                    <div className='mt-4'>
                        With ADP eService you benefit from a timely and accurate response to your Service Requests and 24/7 access. The eService application is only available for registered users. If you do not have access yet, please contact your HR department to provide access.
                    </div>
                </div>


                <div slot="custom-buttons">
                    <SdfButton className="mr-3" onClick={() => { closeModal() }}>
                        Cancel
                    </SdfButton>
                    <SdfLink
                        emphasis="primary"
                        target='_blank'
                        href={ESI_HELP_LINK}
                        onClick={() => closeModal()}
                    >
                        Submit a ticket
                    </SdfLink>
                </div>
            </SdfFocusPane>
        </>
    );
}