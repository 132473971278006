import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const defaultConfigValue = {};

export const AppConfigContext = createContext(defaultConfigValue);

export function AppConfigProvider({ children }) {
	const [config, setConfig] = useState(defaultConfigValue);

	async function fetchConfig() {
		try {
			const { data } = await axios.get(`${process.env.REACT_APP_API_PATH}/app-config`);
			setConfig(data);
		} catch (e) {
			console.error('Error fetching config', e);
		}
	}

	useEffect(() => {
		fetchConfig();
	}, []);

	return (
		<AppConfigContext.Provider value={config}>
			{children}
		</AppConfigContext.Provider>
	);
}

export function withConfig(Component) {
  return function WrapperComponent(props) {
    return (
      <AppConfigContext.Consumer>
        {(config) => <Component {...props} config={config} />}
      </AppConfigContext.Consumer>
    );
  };
}
