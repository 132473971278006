import { APP_ROLES } from "../constants";
import moment from 'moment-timezone';

export const getFormattedDate = dateObj => {
	const [, month, date, year] = dateObj.toDateString().split(' ');
	return `${month} ${date}, ${year}`;
};

export const getFormattedMiniDate = dateObj => {
	const [, month, date] = dateObj.toDateString().split(' ');
	return `${month} ${+date}`;
};

/**
 * @param {Date} date accepts Date or String
 * @returns {String} 'DD/MM/YYYY at h:mmaa ET'
 */
export const getFormattedEasternDateTime = date => {
	const dateString = new Date(date).toLocaleString('en-US', {
		timeZone: 'America/New_York',
		timeZoneName: 'short',
		hour12: true,
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: 'numeric',
		minute: 'numeric'
	});

	return dateString
		.toLowerCase()
		.replace(/\s(?=am|pm)/, '')
		.replace(/et|est|edt/, 'ET')
		.replace(/,\s/, ' at ')
};

/**
 * @param {Date} date accepts Date or String
 * @returns {String} 'DD/MM/YYYY'
 */
export const getFormattedEasternDate = date => {
	return new Date(date).toLocaleString('en-US', {
		timeZone: 'America/New_York',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	});
};

export const getFormattedDateFromString = dateString => {
	return getFormattedDate(new Date(dateString));
};

export const getDateFromString = dateString => {
	const [year, month, day] = dateString.split('-');
	return new Date(year, month - 1, day);
};

export const getFormattedDateFromFormatString = dateString => {
	return getFormattedDate(getDateFromString(dateString));
};

export const getDateStringForSuggestedDate = dateString => {
	const dateObj = getDateFromString(dateString);
	const [day, month, date, year] = dateObj.toDateString().split(' ');
	return `${day}, ${month} ${date}, ${year}`;
};

export const getFormattedDateString = () => {
	const [, month, date, year] = new Date().toDateString().split(' ');
	return `${month.toUpperCase()} ${date}, ${year}`;
};

export const getMonthDateYearFormat = (dateString) => {
	return moment(dateString).format('MM/DD/YYYY');
};

export const adjustTimezone = date => {
	const d = new Date(date);
	d.setMinutes(Math.abs(d.getTimezoneOffset()));
	return d;
};

export const isDateValid = date => {
	return date instanceof Date || ['string', 'number'].includes(typeof date)
		? !isNaN(new Date(date))
		: false;
};

/**
 *
 * @param {String | Date} d1 date to be compared with
 * @param {String | Date} d2 date to compare
 * @returns 0 for [d1] equals [d2]
 * @returns 1 for [d1] after [d2]
 * @returns -1 for [d1] before [d2]
 */
export const compareDates = (d1, d2) => {
	if (!isDateValid(d1) || !isDateValid(d2)) {
		throw RangeError('both values must be valid dates');
	}
	const date1 = new Date(d1).setHours(0, 0, 0, 0);
	const date2 = new Date(d2).setHours(0, 0, 0, 0);
	if (date1 > date2) return 1;
	if (date1 === date2) return 0;
	return -1
};

export const isDateAfter = (d1, d2) => {
	return compareDates(d1, d2) === 1;
};

export const addDays = (days = 0, date) => {
	if (!date || isNaN(new Date(date))) {
		return date;
	}

	const dayInMillis = 86400000;
	const newDateInMillis = new Date(date).getTime() + days * dayInMillis;
	return new Date(newDateInMillis);
};

export const cropImage = (imageFile, cropWidth, cropHeight) => new Promise((resolve, reject) => {
	if (!imageFile) {
		return reject('Unable to crop. File is not valid.');
	}
	const img = new Image();
	img.src = URL.createObjectURL(imageFile);
	img.onload = () => {
		const canvas = document.createElement('canvas');

		canvas.width = cropWidth || 100;
		canvas.height = cropHeight || 100;

		const imgRatio = img.width / img.height;
		const canvasRatio = canvas.width / canvas.height;

		let width = canvas.width;
		let height = canvas.height;

		if (imgRatio < canvasRatio) {
			height = Math.floor(width / imgRatio);
		} else {
			width = Math.floor(height * imgRatio);
		}

		const offsetX = (canvas.width - width) * 0.5;
		const offsetY = (canvas.height - height) * 0.5;

		canvas.getContext('2d').drawImage(img, offsetX, offsetY, width, height);
		resolve(canvas.toDataURL());
	}
});

export const base64ToFile = (base64, fileName) => {
	try {
		const contents = base64.split(',');
		const type = contents[0].match(/:(.*?);/)[1];
		const binaries = atob(contents[1]);
		let size = binaries.length;
		const bytes = new Uint8Array(size);

		while (size--) {
			bytes[size] = binaries.charCodeAt(size);
		}

		return new File([bytes], fileName, { type });
	} catch (e) {
		return null;
	}
};

export const validateEmail = email => {
	return RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(email || '');
};

export const validateName = text => {
	return !removeAccents(text).match(/[^a-z'0-9\s]/gi);
}

export const removeAccents = text => {
	return isString(text)
		? text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
		: '';
};

export const descriptionLength = (value) => {
	const description = [...value];
	let endLineChar = 0;
	let lengthWithoutEndlineChar = 0;
	description.forEach(val => {
		val === '\n' ? endLineChar++ : lengthWithoutEndlineChar++;
	});
	return { endLineChar, lengthWithoutEndlineChar };
};

export const isString = text => typeof text === 'string';

export const isFunction = fn => typeof fn === 'function';


export const hasUSAClientUserRole = (roles = []) => {
	return roles.includes(APP_ROLES.PROJECT_USER) || roles.includes(APP_ROLES.CERTIFICATE_USER) || roles.includes(APP_ROLES.MEMBER_USER);
}

export const hasESIClientUserRole = (roles = []) => {
	return roles.includes(APP_ROLES.ESI_PROJECT_USER) || roles.includes(APP_ROLES.ESI_CERTIFICATE_USER) || roles.includes(APP_ROLES.ESI_MEMBER_USER);
}

export const getSorName = (sor = '', productFullName = false) => {
	if (sor.toLowerCase() === 'hcmng' || sor.toLowerCase() === 'lyric' || sor.toLowerCase() === 'adp lyric' || sor.toLowerCase() === 'custom_hcmng') {
		return productFullName ? 'ADP Lyric' : 'LYRIC';
	}

	else {
		return sor;
	}
}
