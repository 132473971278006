import React, { createContext, useContext, useReducer } from 'react';
import uniqueId from "lodash/uniqueId";

export const ApplicationStateContext = createContext({});

const initialState = {
	navigation: {
		title: '',
		preventScrollOnRouteChange: false,
	},
	loading: false,
	error: {
		show: false,
		message: ''
	},
	projects: {
		reload: false
	},
	apiDiscovery: {
		intentDropdownSelection: null,
		apiDomainDropDownSelection: [],
		subCategoriesDropDownSelection: [],
		domainDropdownList: [],
		subCategoriesDropdownList: [],
		searchResponse: null,
		isApiContext: true
	},
	toasts: {
		list: []
	},
	clientProjectSettings: {
		searchTerm: '',
		filter: [],
		sortOrder: { colId: 'createdDate', sortBy: 'desc' },
		pageNumber: 1,
		pageSize: 10,
		selectedTab: '',
		searchUrl: '',
		selectedRegion: null
	},
	clientOrgsSettings: {
		searchTerm: '',
		filter: [],
		sortOrder: { colId: 'dateCreated', sortBy: 'desc' },
		pageNumber: 1,
		pageSize: 10,
		showDetails: false,
		slideInData: {},
		locationDetails: '',
		selectedRegion: null
	},
	siPartnersSettings: {
		searchTerm: '',
		sortOrder: { colId: 'order', sortBy: 'desc' },
		pageNumber: 1,
		pageSize: 10,
		showDetails: false,
		slideInData: {},
		slideInSearchTerm: '',
		locationDetails: ''
	}
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'changeTitle':
			return {
				...state,
				navigation: {
					...state.navigation,
					title: action.title
				}
			};
		case 'updatePreventScrollOnRouteChange':
			return {
				...state,
				navigation: {
					...state.navigation,
					preventScrollOnRouteChange: action.preventScrollOnRouteChange
				}
			};
		case 'updateLoading':
			return {
				...state,
				loading: action.payload
			};
		case 'showError':
			return {
				...state,
				error: {
					show: true,
					message: action.payload
				}
			};
		case 'setExpiresAt':
			return {
				...state,
				expiresAt: action.payload
			};
		case 'projectsReload':
			return {
				...state,
				projects: {
					reload: action.payload
				}
			};
		case 'updateIntentDropdownSelection':
			return {
				...state,
				apiDiscovery: {
					...state.apiDiscovery,
					intentDropdownSelection: action.payload
				}
			};
		case 'updateDomainDropdownSelection':
			return {
				...state,
				apiDiscovery: {
					...state.apiDiscovery,
					apiDomainDropDownSelection: action.payload
				}
			};
		case 'updateSubCategoriesDropdownSelection':
			return {
				...state,
				apiDiscovery: {
					...state.apiDiscovery,
					subCategoriesDropDownSelection: action.payload
				}
			};
		case 'updateDomainDropdownList':
			return {
				...state,
				apiDiscovery: {
					...state.apiDiscovery,
					domainDropdownList: action.payload
				}
			};

		case 'updateSubCategoriesDropdownList':
			return {
				...state,
				apiDiscovery: {
					...state.apiDiscovery,
					subCategoriesDropdownList: action.payload
				}
			};
		case 'updateSearchResponse':
			return {
				...state,
				apiDiscovery: {
					...state.apiDiscovery,
					searchResponse: action.payload
				}
			};
		case 'updateIsApiContext':
			return {
				...state,
				apiDiscovery: {
					...state.apiDiscovery,
					isApiContext: action.payload
				}
			};
		case 'upsertToasts':
			const upsertList = [...state.toasts.list];
			action.payload.toasts.forEach(({ key, ...rest }) => {
				const index = upsertList.findIndex(value => value.key === key);
				if (key && index >= 0) {
					const oldToast = upsertList[index];
					upsertList.splice(index, 1, { ...oldToast, ...rest });
				} else if (!action.payload.updateOnly) {
					const newKey = key || `random-toast-key-${uniqueId()}`;
					upsertList.push({ key: newKey, ...rest })
				}
			})
			return {
				...state,
				toasts: {
					...state.toasts,
					list: upsertList
				}
			};
		case 'removeToasts':
			const removeList = state.toasts.list.filter(({ key }) => action.payload.keys.findIndex(value => value === key) < 0);
			return {
				...state,
				toasts: {
					...state.toasts,
					list: removeList
				}
			};
		case 'clientProjectSettings':
			return {
				...state,
				clientProjectSettings: {
					searchTerm: action.payload.searchTerm,
					filter: action.payload.filter,
					sortOrder: action.payload.sortOrder,
					pageNumber: action.payload.pageNumber,
					pageSize: action.payload.pageSize,
					selectedTab: action.payload.selectedTab,
					searchUrl: action.payload.searchUrl,
					selectedRegion: action.payload.selectedRegion
				}
			}

		case 'siPartnersSettings':
			return {
				...state,
				siPartnersSettings: {
					searchTerm: action.payload.searchTerm,
					sortOrder: action.payload.sortOrder,
					pageNumber: action.payload.pageNumber,
					pageSize: action.payload.pageSize,
					showDetails: action.payload.showDetails,
					slideInData: action.payload.slideInData,
					slideInSearchTerm: action.payload.slideInSearchTerm,
					locationDetails: action.payload.locationDetails
				}
			}

		case 'clientOrgsSettings':
			return {
				...state,
				clientOrgsSettings: {
					searchTerm: action.payload.searchTerm,
					filter: action.payload.filter,
					sortOrder: action.payload.sortOrder,
					pageNumber: action.payload.pageNumber,
					pageSize: action.payload.pageSize,
					showDetails: action.payload.showDetails,
					slideInData: action.payload.slideInData,
					locationDetails: action.payload.locationDetails,
					selectedRegion: action.payload.selectedRegion
				}
			}

		case 'clearClientOrgSettings':
			return {
				...state,
				clientOrgsSettings: {
					searchTerm: '',
					filter: [],
					sortOrder: { colId: 'dateCreated', sortBy: 'desc' },
					pageNumber: 1,
					pageSize: 10,
					showDetails: false,
					slideInData: {},
					locationDetails: '',
					selectedRegion: null
				}
			}

		case 'clearSiPartnerSettings':
			return {
				...state,
				siPartnersSettings: {
					searchTerm: '',
					sortOrder: { colId: 'order', sortBy: 'desc' },
					pageNumber: 1,
					pageSize: 10,
					showDetails: false,
					slideInData: {},
					slideInSearchTerm: '',
					locationDetails: ''
				}
			}

		default:
			return state;
	}
};

export const ApplicationStateProvider = ({ children }) => (
	<ApplicationStateContext.Provider value={useReducer(reducer, initialState)}>
		{children}
	</ApplicationStateContext.Provider>
);

export const useApplicationState = () => useContext(ApplicationStateContext);

export const updateApplicationTitle = title => ({ type: 'changeTitle', title });
// set loading indicator
export const setLoading = payload => ({ type: 'updateLoading', payload });
// set error indicator
export const showErrorModal = payload => ({ type: 'showError', payload });
export const hideErrorModal = () => ({ type: 'hideError' });
// set expiresAt
export const setExpiresAt = payload => ({ type: 'setExpiresAt', payload });
export const updatePreventScrollOnRouteChange = prevent => ({ type: 'updatePreventScrollOnRouteChange', preventScrollOnRouteChange: prevent });
// set and unset projects reload
export const setProjectsReload = payload => ({ type: 'projectsReload', payload });

export const updateIntentDropdownSelection = payload => ({ type: 'updateIntentDropdownSelection', payload });
export const updateDomainDropdownSelection = payload => ({ type: 'updateDomainDropdownSelection', payload });
export const updateSubCategoriesDropdownSelection = payload => ({ type: 'updateSubCategoriesDropdownSelection', payload });

export const updateDomainDropdownList = payload => ({ type: 'updateDomainDropdownList', payload });
export const updateSubCategoriesDropdownList = payload => ({ type: 'updateSubCategoriesDropdownList', payload });
export const updateSearchResponse = payload => ({ type: 'updateSearchResponse', payload });

export const setClientProjectSettings = payload => ({ type: 'clientProjectSettings', payload });
export const setClientOrgsSettings = payload => ({ type: 'clientOrgsSettings', payload });
export const setSiPartnersSettings = payload => ({ type: 'siPartnersSettings', payload });
export const clearClientOrgSettings = () => ({ type: 'clearClientOrgSettings' });
export const clearSiPartnerSettings = () => ({ type: 'clearSiPartnerSettings' });

export const updateIsApiContext = payload => ({ type: 'updateIsApiContext', payload });

/**
 *
 * @typedef {{
 * 	message?: string,
* 	messageClassName?: string,
* 	onMessageClose?: () => unknown,
* 	buttonOnClick?: () => unknown,
* 	buttonText?: string,
*   buttonBusy?: boolean,
* 	buttonDisabled?: boolean,
* 	autoCloseAfter?: number,
* 	key?: string
* }} Toast
 * @param {Toast | Array<Toast>} toasts
 * @param {boolean} updateOnly
 * @returns
 */
export const upsertToasts = (toasts, updateOnly) => {
	if (!Array.isArray(toasts)) toasts = [toasts];
	return { type: 'upsertToasts', payload: { toasts, updateOnly } };
}

/**
 *
 * @param {string | Array<string>} keys
 * @returns
 */
export const removeToasts = (keys) => {
	if (!Array.isArray(keys)) keys = [keys];
	return { type: 'removeToasts', payload: { keys } };
}
