import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { get, uniqueId } from 'lodash';
import Toast from './common/toast';

const defaultSessionValue = {
  state: "LOADING",
  partners: []
};

export const SessionContext = createContext(defaultSessionValue);

export function SessionProvider({ children }) {
  const [initialSession, setInitialSession] = useState(defaultSessionValue);
  const [session, setSession] = useState(defaultSessionValue);
  const [toast, setToast] = useState(false);

  const handleResponse = (message, status = "error") => {
    setToast({
      key: uniqueId(),
      status: status,
      message: message,
      showToast: true
    });
  };

  async function fetchPartners() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/partners?page=0&pageSize=10`);
      const data = get(response, 'data.content', []);
      if (data.length > 0) {
        setSession({ ...initialSession, partners: data });
      }
      else {
        setSession({ ...initialSession, partners: [] });
      }
    } catch (e) {
      const errorMessage = get(e, 'response.data.message', 'Something went wrong while fetching Partner details. Please try again.');
      setSession({ ...initialSession, partners: [] });
      handleResponse(errorMessage);
    }
  }

  useEffect(() => {
    if (initialSession.state !== "LOADING" && initialSession.roles.includes('clientSelfServiceProjectUser')) {
      fetchPartners();
    }
    else {
      setSession({ ...initialSession, partners: [] });
    }
  }, [initialSession])


  useEffect(() => {
    async function fetchSession() {
      try {
        const result = await axios(
          `${process.env.REACT_APP_API_PATH}/session`, { headers: { 'Cache-Control': 'no-cache' } }
        );
        if (result.data.organizationName.includes('admin')) {
          result.data.role = 'ADMIN';
        }
        // result.data.region = 'eu';
        // result.data.roles = [
        //   "euClientSelfServiceTemplateadmin",
        //   "euClientSelfServiceSuperadmin",
        //   "euClientSelfServiceProjectUser",
        //   "euClientSelfServiceCertificateUser",
        //   "euSelfServiceClientadmin"]
        setInitialSession(result.data);
        // console.log('Session:', result.data);

        if (result.data.pendoVisitorId && result.data.pendoAccountId) {
          window.pendo.initialize({
            visitor: {
              id: result.data.pendoVisitorId
            },
            account: {
              id: result.data.pendoAccountId
            }
          });
        }
      } catch (e) {
        console.log("window.location.pathname: ", window.location.pathname);
        if (
          window.location.pathname !==
          `${process.env.REACT_APP_ROOT_PATH}/access-denied` &&
          window.location.pathname !==
          `${process.env.REACT_APP_ROOT_PATH}/login` &&
          window.location.pathname !==
          `${process.env.REACT_APP_ROOT_PATH}/error`
        ) {
          window.location.href = process.env.REACT_APP_API_PATH.indexOf('https') === 0
            ? `${process.env.REACT_APP_API_PATH}/authenticate`
            : `https://${process.env.REACT_APP_DOMAIN + process.env.REACT_APP_API_PATH}/authenticate`;
        } else {
          setInitialSession({ roles: [] });
        }
      }
    }

    fetchSession();
  }, []);

  return (<>
    {toast && <Toast
      key={toast.key}
      status={toast.status}
      message={toast.message}
      showToast={toast.showToast}
    />}
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  </>
  );
}

export function withPermissionsAndRoles(Component) {
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(session) => (
          <Component
            {...props}
            permissions={session.permissions}
            roles={session.roles}
          />
        )}
      </SessionContext.Consumer>
    );
  };
}

export function withSession(Component) {
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(session) => <Component {...props} session={session} />}
      </SessionContext.Consumer>
    );
  };
}
