import React, { useState, useEffect, useRef } from 'react';
import { SdfButton, SdfCard, SdfIcon, SdfLink, SfcShellAppBarIcon } from '@synerg/react-components';
import { SHELL_NAV_ICON, ZENDESK_LINK } from '../../constants';
import { hasESIClientUserRole, hasUSAClientUserRole } from '../../common/util';
import ConsultingModal from '../../common/consultingModal.jsx';
import HelpModalESI from '../../common/helpModalESI.jsx';

export default function HelpIcon({ roles = [] }) {
	const [showHelpMenu, setShowHelpMenu] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isOpenEsiHelpModal, setIsOpenEsiHelpModal] = useState(false);

	const menuRef = useRef(null);
	const helpIconRef = useRef(null);

	useEffect(() => {
		document.body.addEventListener('click', onClickOutside);
		return () => document.removeEventListener('click', onClickOutside);
	}, []);

	const onClickOutside = e => {
		const element = e.target;
		if (helpIconRef.current && helpIconRef.current.contains(element)) {
			return;
		}
		if (menuRef.current && !menuRef.current.contains(element)) {
			return setShowHelpMenu(false);
		}
	};

	return (
		<>
			<SfcShellAppBarIcon
				slot="icons"
				icon="action-help"
				ref={helpIconRef}
				className={`${SHELL_NAV_ICON} md:mr-4`}
				label="Help"
				href="javascript:void(0)"
				onClick={() => {
					setShowHelpMenu(!showHelpMenu);
				}}
			/>

			<div
				className={showHelpMenu ? '' : 'hidden'}
				ref={menuRef}
				style={{ position: 'fixed', top: '4rem', right: '5rem' }}
			>
				<SdfCard>
					<div className='text-default font-medium mb-4 font-bold'>Need help?</div>
					{hasESIClientUserRole(roles) ?
						<SdfButton emphasis='primary' onClick={() => { setIsOpenEsiHelpModal(true) }}>
							Contact support
						</SdfButton>
						:
						<SdfLink emphasis="primary" href={ZENDESK_LINK} target="_blank">
							<span>Contact support</span>
						</SdfLink>}

					{hasUSAClientUserRole(roles) && <>
						<div className='text-default font-medium mt-8 font-bold mb-1'>Need implementation consultation?</div>
						<div className='text-default message-container size-md'>Purchase one-on-one consultation</div>
						<div className='text-default message-container size-md'>by the hour.</div>
						<SdfLink emphasis="none" className="mt-1" onClick={() => setIsModalOpen(true)}>
							<SdfIcon className="mr-1" icon="external-link" />
							Learn more
						</SdfLink>
					</>}
				</SdfCard>
			</div>
			{isModalOpen && <ConsultingModal {...{ isModalOpen, setIsModalOpen }} />}
			{isOpenEsiHelpModal && <HelpModalESI isOpenEsiHelpModal={isOpenEsiHelpModal} setIsOpenEsiHelpModal={setIsOpenEsiHelpModal} />}
		</>
	);
}
