import React from 'react';
import { withPermissionsAndRoles, withSession } from '../../session';
import NotificationIcon from './notificationIcon';
import HelpIcon from './helpIcon';
import FeedbackIcon from './feedbackIcon';
import { hasESIClientUserRole, hasUSAClientUserRole } from '../../common/util';

function TopMenuOptions({ roles = [], session }) {
  return (
    <>
      {hasUSAClientUserRole(roles) || hasESIClientUserRole(roles) && <>
        <FeedbackIcon />
        <NotificationIcon region={session.region} />
        <HelpIcon roles={roles} />
      </>}
    </>
  );
}

export default withSession(withPermissionsAndRoles(TopMenuOptions));
