import React from 'react';
import { SdfButton, SdfFocusPane, SdfLink } from '@synerg/react-components';
import { ZENDESK_LINK } from '../constants';


export default function ConsultingModal({ isModalOpen, setIsModalOpen }) {

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<SdfFocusPane
				className="text-default"
				paneType="floating"
				visible={isModalOpen}
				closeable={true}
				heading={'Consulting Request'}
				dismissOnClickOutside={true}
				hideDismissButton={true}
				hideAcceptButton={true}
				onSdfDismiss={() => {
					closeModal();
				}}
				spacing="loose"
				size="md"
			>
				<div className="text-heading-03">
					ADP API specialists can guide you through your development efforts.
					Consulting includes selecting appropriate APIs, guidance on interacting with the applications you're integrating with, and development best practices to complete your project quickly.
				</div>

				<div className='mt-8 italic text-sm text-secondary'>
					Note: The rate for one-on-one consulting services is $250/hour billed to your current plan.
				</div>

				<div slot="custom-buttons">
					<SdfButton className="mr-3" onClick={() => { closeModal() }}>
						Cancel
					</SdfButton>
					<SdfLink
						emphasis="primary"
						target='_blank'
						href={ZENDESK_LINK}
						onClick={() => closeModal()}
					>
						Book A Consultation
					</SdfLink>
				</div>
			</SdfFocusPane>
		</>
	);
}