export const APP_ROLES = {
  PROJECT_USER: "clientSelfServiceProjectUser",
  CERTIFICATE_USER: "clientSelfServiceCertificateUser",
  MEMBER_USER: "clientSelfServiceMemberUser",
  TEMPLATE_ADMIN: "clientSelfServiceTemplateadmin",
  SUPER_ADMIN: "clientSelfServiceSuperadmin",
  CLIENT_ADMIN: "selfServiceClientadmin",
  ESI_PROJECT_USER: 'euClientSelfServiceProjectUser',
  ESI_CERTIFICATE_USER: 'euClientSelfServiceCertificateUser',
  ESI_MEMBER_USER: 'euClientSelfServiceMemberUser',
  ESI_TEMPLATE_ADMIN: 'euClientSelfServiceTemplateadmin',
  ESI_SUPER_ADMIN: 'euClientSelfServiceSuperadmin',
  ESI_CLIENT_ADMIN: 'euSelfServiceClientadmin'
};

export const ROLE_NAMES = {
  SA: "Client Self Service Super Admin",
  CA: "Client Self Service Client Admin",
  TA: "Client Self Service Template Admin",
  ESA: "ESI Client Self Service Super Admin",
  ECA: "ESI Client Self Service Client Admin",
  ETA: "ESI Client Self Service Template Admin"
}

export const appRolesMap = {
  [APP_ROLES.PROJECT_USER]: 'Project user',
  [APP_ROLES.CERTIFICATE_USER]: 'Certificate user',
  [APP_ROLES.MEMBER_USER]: 'Member user',
  [APP_ROLES.TEMPLATE_ADMIN]: 'Template admin',
  [APP_ROLES.SUPER_ADMIN]: 'Super admin',
  [APP_ROLES.CLIENT_ADMIN]: 'Client admin',
  [APP_ROLES.ESI_PROJECT_USER]: 'ESI Project user',
  [APP_ROLES.ESI_CERTIFICATE_USER]: 'ESI Certificate user',
  [APP_ROLES.ESI_MEMBER_USER]: 'ESI Member user',
  [APP_ROLES.ESI_TEMPLATE_ADMIN]: 'ESI Template admin',
  [APP_ROLES.ESI_SUPER_ADMIN]: 'ESI Super admin',
  [APP_ROLES.ESI_CLIENT_ADMIN]: 'ESI Client admin',
}

export const ZENDESK_LINK = 'https://clientsupport.adp.com/hc/en-us/signin?return_to=https%3A%2F%2Fclientsupport.adp.com%2Fhc%2Fen-us%2Frequests%2Fnew&locale=en-us';

export const ESI_HELP_LINK = 'https://serviceportal.adp.com/siebel/eservice/common';

export const DEV_PORTAL_LINK = 'https://developers.adp.com';

export const PAGE_SIZES = [{ label: '10', value: 10 }, { label: '20', value: 20 }, { label: '40', value: 40 }, { label: '60', value: 60 }, { label: '80', value: 80 }, { label: '100', value: 100 }];

export const SHELL_NAV_ICON = 'items-center mr-24 cursor-pointer w-10'
